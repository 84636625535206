import { has } from './helpers';

const logo = require(`../../../assets/img/logos/${process.env.BRAND}.svg`);
const caret = require('../../../assets/img/icons/caret.svg');
const search = require('../../../assets/img/icons/mi-magnifying-glass.svg');
const user = require('../../../assets/img/icons/user.svg');
const userAuthenticated = require('../../../assets/img/icons/user-authenticated.svg');
const menu = require('../../../assets/img/icons/mi-bars-horizontal.svg');
const help = require('../../../assets/img/icons/mi-question-circle.svg');
const close = require('../../../assets/img/icons/close.svg');
// const settings = require('../../../assets/img/icons/settings.svg');
// const logout = require('../../../assets/img/icons/logout.svg');
// const bell = require('../../../assets/img/icons/bell.svg');
// const code = require('../../../assets/img/icons/code.svg');
// const arrowRight = require('../../../assets/img/icons/arrow-right.svg');
const chevronRight = require('../../../assets/img/icons/chevron-right.svg');
// const alarm = require('../../../assets/img/icons/alarm.svg');
const clear = require('../../../assets/img/icons/clear.svg');
// const lock = require('../../../assets/img/icons/lock.svg');

export {
  getIcon,
  logo
};

const icons = {
  caret,
  search,
  user,
  menu,
  close,
  help,
  // logout,
  // bell,
  // code,
  // arrowRight,
  chevronRight,
  // alarm,
  clear,
  // lock,
  userAuthenticated
};

/** returns the desired icon if it exists or defaults to close */
const getIcon = (icon) => {
  return has(icons, icon) ? icons[icon] : icons.close;
};

